import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  business_list,
} from '../../api/locations'
import actions from './actions'

export function* GET_LOCATIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(business_list, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        businessDetails: response?.data?.businesses,
        businessName: response?.data?.business_name,
        themeColor: response?.data?.theme_color,
        backgroundImage: response?.data?.background_image_url,
        backgroundLogo: response?.data?.business_logo,
        pageName: response?.data?.location_page_title,
        pageDescription: response?.data?.location_page_description,
        favIconImage: response?.data?.location_favicon,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LOCATIONS, GET_LOCATIONS),
  ])
}
