import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const searchOptions = {
  componentRestrictions: { country: ['ca', 'us'] }
}

const LocationSearchInput = ({onSelectedAddress}) => {

  const [address, setAddress] = React.useState('')

  const handleChange = address => {
    setAddress(address)
    document.getElementById("clear_icon_div").classList.remove('hide_icon')
  }

  const handleSelect = address => {
    setAddress(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => onSelectedAddress(latLng.lat, latLng.lng))
      .catch(error => console.error('Error', error))
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search by address or city',
              className: 'pac-target-input',
            })}
            id='search-input'
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'pac-item-selected'
                : 'pac-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span class="pac-icon pac-icon-marker"></span>
                  <span class="pac-item-query">{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationSearchInput