import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import locations from './locations/reducers'
import RootAction from './actions'

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    locations,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
