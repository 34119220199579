import { createSelector } from 'reselect'

export const selectLocations = (state) => state.locations

export const selectLocationsData = createSelector(
  [selectLocations],
  (locations) => {
    return locations.data
  },
)
