import Location from './components/Location'
import { useSelector } from 'react-redux'
import { selectLocations } from './redux/locations/selectors'
import './App.css';

function App() {

  const locations = useSelector(selectLocations)

  return (
    <div className='container' style={{ background: `url(${locations.backgroundImage}) center center / cover` }}>
      <Location />
    </div>
  );
}

export default App;
