import axios from 'axios'
// import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.plento.app/v1/",
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Device-Type': 'Website Portal',
      'X-App-Version': '2.0.0',
    },
  },
})

apiClient.interceptors.response.use(
  (response) => {
    // if (response?.data?.message != null) {
      // const { message } = response.data
      // notification.success({
      //  message,
      // })
    // }
    return response
  },
  (error) => {
    // if (error?.response?.data != null) {
      // const { message } = error.response.data
      // notification.error({
      //  message,
      // })
    // }
    throw error
  },
)

export default apiClient
