import apiClient from '../services/axios'

const BASE_URL = 'manage-location'

export async function business_list(payload) {
  return apiClient
    .post(`${BASE_URL}/list`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
